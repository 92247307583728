import { icon } from "@fortawesome/fontawesome-svg-core"

export const HomeNav = [
    {
        id: 'NHO001',
        icon: 'pe-7s-home',
        label: 'Home',
        to: '/home',
        category: 'INICIO'
    }
]
export const AppNav = [
    {
        id: 'GEN001',
        icon: 'pe-7s-network',
        label: 'Empresas',
        to: '/app/empresas',
        category: 'INICIO'
    }
]
export const AdminNav = [
    {
        id: 'NAD000',
        icon: 'pe-7s-users',
        label: 'Libro Reclamaciones',
        to: '/admin/libro',
        category: 'ADMINISTRADOR'
    },
    {
        id: 'NAD001',
        icon: 'pe-7s-users',
        label: 'Usuarios',
        to: '/admin/users',
        category: 'ADMINISTRADOR'
    },
    {
        id: 'NAD002',
        icon: 'pe-7s-lock',
        label: 'Autorización',
        category: 'ADMINISTRADOR'
    },
    {
        id: 'NAD002-1',
        parentId: 'NAD002',
        label: 'Roles',
        to: '/admin/roles',
        category: 'ADMINISTRADOR ➜ AUTORIZACIÓN'
    },
    {
        id: 'NAD002-2',
        parentId: 'NAD002',
        label: 'Modulos',
        to: '/admin/modulos',
        category: 'ADMINISTRADOR ➜ AUTORIZACIÓN'
    },
    {
        id: 'NAD002-3',
        parentId: 'NAD002',
        label: 'Categorias',
        to: '/admin/categorias',
        category: 'ADMINISTRADOR ➜ AUTORIZACIÓN'
    },
    {
        id: 'NAD002-4',
        parentId: 'NAD002',
        label: 'Servicios',
        to: '/admin/servicios',
        category: 'ADMINISTRADOR ➜ AUTORIZACIÓN'
    },
    {
        id: 'NAD002-5',
        parentId: 'NAD002',
        label: 'Paquetes',
        to: '/admin/paquetes',
        category: 'ADMINISTRADOR  ➜ AUTORIZACIÓN'
    },
    {
        id: 'NAD002-6',
        parentId: 'NAD002',
        label: 'Paquetes de Facturación',
        to: '/admin/paquetefacturacion',
        category: 'ADMINISTRADOR  ➜ AUTORIZACIÓN'
    },
    {
        id: 'NAD002-7',
        parentId: 'NAD002',
        label: 'Ventas',
        to: '/admin/ventas',
        category: 'ADMINISTRADOR  ➜ AUTORIZACIÓN'
    },
    {
        id: 'NAD002-8',
        parentId: 'NAD002',
        label: 'Reporte Ventas Internas',
        to: '/admin/reporteVentas',
        category: 'ADMINISTRADOR  ➜ AUTORIZACIÓN'
    },
    {
        id: 'NAD002-9',
        parentId: 'NAD002',
        label: 'Reporte Gerencial',
        to: '/admin/reporteGerencial',
        category: 'ADMINISTRADOR  ➜ AUTORIZACIÓN'
    },
    {
        id: 'NAD003',
        icon: 'pe-7s-users',
        label: 'Envio de Whatsapp/Email',
        to: '/admin/send-whatsapp-email',
        category: 'ADMINISTRADOR'
    },
    {
        id: 'NAD004',
        icon: 'pe-7s-users',
        label: 'Notificaciones',
        to: '/admin/notifications',
        category: 'ADMINISTRADOR'
    },
    {
        id: 'NAD005',
        icon: 'pe-7s-lock',
        label: 'Control Interno',
        category: 'ADMINISTRADOR'
    },
    {
        id: 'NAD005-1',
        parentId: 'NAD005',
        label: 'Empresas sin carpetas Drive',
        to: '/admin/empresas-drive',
        category: 'ADMINISTRADOR ➜ CONTROL INTERNO'
    },
    {
        id: 'NAD005-2',
        parentId: 'NAD005',
        label: 'Control de Empresas',
        to: '/admin/control-empresas',
        category: 'ADMINISTRADOR ➜ CONTROL INTERNO'
    },
    {
        id: 'NAD005-3',
        parentId: 'NAD005',
        label: 'Servidores',
        to: '/admin/servidores',
        category: 'ADMINISTRADOR ➜ CONTROL INTERNO'
    }
]
export const ContablesNav = [
    {
        nro: 1,
        id: 'CON001',
        icon: 'pe-7s-shopbag',
        label: 'Inventario Inicial',
        to: '/contable/inventarios_iniciales',
        category: 'ANOTACIÓN DE OPERACIONES'
    },
    {
        nro: 2,
        id: 'CON002',
        icon: 'pe-7s-shopbag',
        label: 'Compras',
        to: '/contable/compras',
        category: 'ANOTACIÓN DE OPERACIONES'
    },
    {
        nro: 5,
        id: 'CON003',
        icon: 'pe-7s-wallet',
        label: 'Cuentas por Cobrar',
        to: '/contable/cuentas/cobrar',
        category: 'ANOTACIÓN DE OPERACIONES'
    },
    {
        nro: 4,
        id: 'CON004',
        icon: 'pe-7s-cart',
        label: 'Ventas',
        to: '/contable/ventas',
        category: 'ANOTACIÓN DE OPERACIONES'
    },
    {
        nro: 3,
        id: 'CON005',
        icon: 'pe-7s-cash',
        label: 'Cuentas por Pagar',
        to: '/contable/cuentas/pagar',
        category: 'ANOTACIÓN DE OPERACIONES'
    },
    {
        nro: 6,
        id: 'CON006',
        icon: 'pe-7s-cash',
        label: 'Asientos Varios',
        to: '/contable/asientos_varios',
        category: 'ANOTACIÓN DE OPERACIONES'
    },
    {
        nro: 7,
        id: 'CON007',
        icon: 'pe-7s-cash',
        label: 'Planillas',
        to: '/contable/planillas',
        category: 'ANOTACIÓN DE OPERACIONES'
    },
    {
        nro: 8,
        id: 'CON008',
        icon: 'pe-7s-cash',
        label: 'Cierre Contable',
        to: '/contable/cierre/contable',
        category: 'ANOTACIÓN DE OPERACIONES'
    }
]
export const AplicativosNav = [
    {
        nro: 1,
        id: 'APP001',
        icon: 'pe-7s-id',
        label: 'DNI',
        category: 'CONSULTAS ONLINE'
    },
    {
        nro: 2,
        id: 'APP001-1',
        parentId: 'APP001',
        icon: 'pe-7s-ticket',
        label: 'Básico',
        to: '/aplicativos/dnibasico',
        category: 'CONSULTAS ONLINE ➜ DNI'
    },
    {
        nro: 3,
        id: 'APP001-2',
        parentId: 'APP001',
        icon: 'pe-7s-ticket',
        label: 'Dni por Nombre',
        to: '/aplicativos/dnipornombre',
        category: 'CONSULTAS ONLINE ➜ DNI'
    },
    {
        nro: 4,
        id: 'APP001-3',
        parentId: 'APP001',
        icon: 'pe-7s-ticket',
        label: 'Premium',
        to: '/aplicativos/dniPremium',
        category: 'CONSULTAS ONLINE ➜ DNI'
    },
    {
        nro: 5,
        id: 'APP001-4',
        parentId: 'APP001',
        icon: 'pe-7s-ticket',
        label: 'Planitum',
        to: '/aplicativos/dniPlatinium',
        category: 'CONSULTAS ONLINE ➜ DNI'
    },
    {
        nro: 6,
        id: 'APP002',
        icon: 'pe-7s-network',
        label: 'LICENCIAS - MTC',
        to: '/aplicativos/licencias',
        category: 'CONSULTAS ONLINE'
    },
    {
        nro: 7,
        id: 'APP003',
        icon: 'pe-7s-note2',
        label: 'RUC',
        category: 'CONSULTAS ONLINE'
    },
    {
        nro: 8,
        id: 'APP003-1',
        parentId: 'APP003',
        icon: 'pe-7s-ticket',
        label: 'Reducido',
        to: '/aplicativos/rucreducido',
        category: 'CONSULTAS ONLINE ➜ RUC'
    },
    {
        nro: 9,
        id: 'APP003-2',
        parentId: 'APP003',
        icon: 'pe-7s-ticket',
        label: 'Básico',
        to: '/aplicativos/rucbasico',
        category: 'CONSULTAS ONLINE ➜ RUC'
    },
    {
        nro: 10,
        id: 'APP003-3',
        parentId: 'APP003',
        icon: 'pe-7s-ticket',
        label: 'Premium',
        to: '/aplicativos/rucpremium',
        category: 'CONSULTAS ONLINE ➜ RUC'
    },
    {
        nro: 11,
        id: 'APP004',
        icon: 'pe-7s-network',
        label: 'ESSALUD',
        to: '/aplicativos/essalud',
        category: 'CONSULTAS ONLINE'
    },
    {
        nro: 12,
        id: 'APP005',
        icon: 'pe-7s-news-paper',
        label: 'VALIDEZ DE CPE',
        category: 'CONSULTAS ONLINE'
    },
    {
        nro: 13,
        id: 'APP005-1',
        parentId: 'APP005',
        label: 'Validez CPE',
        to: '/aplicativos/validez/cpe',
        category: 'CONSULTAS ONLINE ➜ CPE'
    },
    {
        nro: 14,
        id: 'APP005-2',
        parentId: 'APP005',
        label: 'Validez XML',
        to: '/aplicativos/validez/xml',
        category: 'CONSULTAS ONLINE ➜ CPE'
    },
    {
        nro: 15,
        id: 'APP006',
        icon: 'pe-7s-paper-plane',
        label: 'CONSULTA CCI - BN',
        to: '/aplicativos/cci',
        category: 'CONSULTAS ONLINE'
    },
    {
        nro: 16,
        id: 'APP007',
        icon: 'pe-7s-date',
        label: 'TIPO DE CAMBIO',
        to: '/aplicativos/tipocambio',
        category: 'CONSULTAS ONLINE'
    },
    {
        nro: 17,
        id: 'APP008',
        icon: 'pe-7s-notebook',
        label: 'REMYPE',
        to: '/aplicativos/remype',
        category: 'CONSULTAS ONLINE'
    },
    {
        nro: 18,
        id: 'APP009',
        icon: 'pe-7s-wallet',
        label: 'OBLIGADOS CPE',
        to: '/aplicativos/obligados',
        category: 'CONSULTAS ONLINE'
    },
    {
        nro: 19,
        id: 'APP010',
        icon: 'pe-7s-note2',
        label: 'CONSULTAS AFP',
        category: 'CONSULTAS ONLINE'
    },
    {
        nro: 20,
        id: 'APP010-1',
        parentId: 'APP010',
        label: 'Comisiones afp',
        to: '/aplicativos/cafp',
        category: 'CONSULTAS ONLINE ➜ CONSULTAS AFP'
    },
    {
        nro: 21,
        id: 'APP010-2',
        parentId: 'APP010',
        label: 'Afiliados afp',
        to: '/aplicativos/afafp',
        category: 'CONSULTAS ONLINE ➜'
    },
]
export const GeneradorPdfNav = [
    {
        nro: 1,
        id: 'GEN001',
        icon: 'pe-7s-id',
        label: 'Generador PDF',
        to: '/aplicativos/pdf',
        category: 'GENERADOR PDF'
    }
]
export const EstusFinanNav = [
    {
        nro: 1,
        id: 'SFI001',
        icon: 'pe-7s-shopbag',
        label: 'EE.FF - Contables',
        category: 'ESTADO FINANCIEROS'
    },
    {
        nro: 2,
        id: 'SFI001-1',
        parentId: 'SFI001',
        label: 'Balance General',
        to: '/repo/ef/bgc',
        category: 'ESTADO FINANCIEROS ➜ EE.FF - CONTABLES'
    },
    {
        nro: 3,
        id: 'SFI001-2',
        parentId: 'SFI001',
        label: 'E.G. Y P-Naturaleza',
        to: '/repo/ef/ern',
        category: 'ESTADO FINANCIEROS ➜ EE.FF - CONTABLES'
    },
    {
        nro: 4,
        id: 'SFI001-3',
        parentId: 'SFI001',
        label: 'E.G. Y P-Función',
        to: '/repo/ef/erf',
        category: 'ESTADO FINANCIEROS ➜ EE.FF - CONTABLES'
    },
    {
        nro: 5,
        id: 'SFI002',
        icon: 'pe-7s-shopbag',
        label: 'EE.FF - SMV',
        category: 'ESTADO FINANCIEROS'
    },
    {
        nro: 6,
        id: 'SFI002-1',
        parentId: 'SFI002',
        label: 'Estado de Situacion Financiera',
        to: '/repo/smv/esf',
        category: 'ESTADO FINANCIEROS ➜ EE.FF - SMV'
    },
    {
        nro: 7,
        id: 'SFI002-2',
        parentId: 'SFI002',
        label: 'Estado de resultados',
        to: '/repo/smv/edr',
        category: 'ESTADO FINANCIEROS ➜ EE.FF - SMV'
    },
    {
        nro: 8,
        id: 'SFI002-3',
        parentId: 'SFI002',
        label: 'Estado de cambios en el P.N.',
        to: '/repo/smv/cambios-pn',
        category: 'ESTADO FINANCIEROS ➜ EE.FF - SMV'
    },
    {
        nro: 9,
        id: 'SFI002-4',
        parentId: 'SFI002',
        label: 'Estado de flujos de Efectivo',
        to: '/repo/ventas141',
        category: 'ESTADO FINANCIEROS ➜ EE.FF - SMV'
    },
    {
        nro: 10,
        id: 'SFI002-5',
        parentId: 'SFI002',
        label: 'Notas a los EE.FF - SMV',
        to: '/repo/ventas141',
        category: 'ESTADO FINANCIEROS ➜ EE.FF - SMV'
    },
]
export const FacturacionNav = [
    {
        nro: 1,
        id: 'FAC001',
        icon: 'pe-7s-credit',
        label: 'Emitir CPE',
        to: '/fac/facturador',
        category: 'FACTURACIÓN ELECTRÓNICA'
    },
    {
        nro: 2,
        id: 'FAC002',
        icon: 'pe-7s-note2',
        label: 'Lista de Comprobantes',
        to: '/fac/factura',
        category: 'FACTURACIÓN ELECTRÓNICA'
    },
    {
        nro: 3,
        id: 'FAC003',
        icon: 'pe-7s-note2',
        label: 'Lista de Notas',
        to: '/fac/notas',
        category: 'FACTURACIÓN ELECTRÓNICA'
    },
    {
        nro: 4,
        id: 'FAC004',
        icon: 'pe-7s-safe',
        label: 'CPE-Contingencias',
        to: '/fac/contingencias',
        category: 'FACTURACIÓN ELECTRÓNICA'
    },
    {
        nro: 5,
        id: 'FAC005',
        icon: 'pe-7s-note',
        label: 'Lista de Contingencias',
        to: '/fac/lisContingencias',
        category: 'FACTURACIÓN ELECTRÓNICA'
    },
    {
        nro: 6,
        id: 'FAC006',
        icon: 'pe-7s-news-paper',
        label: 'Emitir Guías de Remisión',
        to: '/fac/guias',
        category: 'FACTURACIÓN ELECTRÓNICA'
    },
    {
        nro: 7,
        id: 'FAC007',
        icon: 'pe-7s-portfolio',
        label: 'Lista de Guías de Remisión',
        to: '/fac/lisGuias',
        category: 'FACTURACIÓN ELECTRÓNICA'
    },
    {
        nro: 8,
        id: 'FAC008',
        icon: 'pe-7s-note',
        label: 'Lista de Proformas',
        to: '/fac/proformas',
        category: 'FACTURACIÓN ELECTRÓNICA'
    }
]
export const LibElecNav = [
    {
        nro: 1,
        id: "LEL001",
        icon: 'pe-7s-bookmarks',
        label: 'Registro de Compras',
        category: 'LIBROS ELECTRONICOS'
    },
    {
        nro: 2,
        id: 'LEL001-1',
        parentId: 'LEL001',
        label: 'Compras 8.1',
        to: '/sleple5.1/compras/8.1',
        category: 'LIBROS ELECTRONICOS ➜ REGISTRO DE COMPRAS '
    },
    {
        nro: 3,
        id: 'LEL001-2',
        parentId: 'LEL001',
        label: 'Compras 8.2',
        to: '/sleple5.1/compras/8.2',
        category: 'LIBROS ELECTRONICOS ➜ REGISTRO DE COMPRAS '
    },
    {
        nro: 4,
        id: 'LEL001-3',
        parentId: 'LEL001',
        label: 'Compras 8.3',
        to: '/sleple5.1/compras/8.3',
        category: 'LIBROS ELECTRONICOS ➜ REGISTRO DE COMPRAS '
    },
    {
        nro: 5,
        id: "LEL002",
        icon: 'pe-7s-notebook',
        label: 'Registro de Ventas',
        category: 'LIBROS ELECTRONICOS'
    },
    {
        nro: 6,
        id: 'LEL002-1',
        parentId: 'LEL002',
        label: 'Ventas 14.1',
        to: '/sleple5.1/ventas/14.1',
        category: 'LIBROS ELECTRONICOS ➜ REGISTRO DE VENTAS '
    },
    {
        nro: 7,
        id: 'LEL002-2',
        parentId: 'LEL002',
        label: 'Ventas 14.2',
        to: '/sleple5.1/ventas/14.2',
        category: 'LIBROS ELECTRONICOS ➜ REGISTRO DE VENTAS '
    },
    {
        nro: 8,
        id: "LEL003",
        icon: 'pe-7s-note2',
        label: 'Libro Diario',
        category: 'LIBROS ELECTRONICOS'
    },
    {
        nro: 9,
        id: 'LEL003-1',
        parentId: 'LEL003',
        label: 'Libro Diario',
        to: '/sleple5.1/diario/5.1',
        category: 'LIBROS ELECTRONICOS ➜ LIBRO DIARIO'
    },
    {
        nro: 10,
        id: 'LEL003-2',
        parentId: 'LEL003',
        label: 'PCGE',
        to: '/sleple5.1/diario/5.3',
        category: 'LIBROS ELECTRONICOS ➜ LIBRO DIARIO'
    },
    {
        nro: 11,
        id: "LEL004",
        icon: 'pe-7s-note2',
        label: 'Libro Diario Simplificado',
        category: 'LIBROS ELECTRONICOS'
    },
    {
        nro: 12,
        id: 'LEL004-1',
        parentId: 'LEL004',
        label: 'Libro Diario Simplificado ➜ LIBRO DIARIO SIMPLIFICADO',
        to: '/sleple5.1/simplificado/5.2',
        category: 'LIBROS ELECTRONICOS'
    },
    {
        nro: 13,
        id: 'LEL004-2',
        parentId: 'LEL004',
        label: 'PCGE',
        to: '/sleple5.1/simplificado/5.4 ➜ LIBRO DIARIO SIMPLIFICADO',
        category: 'LIBROS ELECTRONICOS'
    },
    {
        nro: 14,
        id: "LEL005",
        icon: 'pe-7s-ribbon',
        label: 'Libro Mayor',
        category: 'LIBROS ELECTRONICO',
        to: '/sleple5.1/mayor/6.1',
    },
]
export const MovimientosNav = [
    {
        id: 'MOV001',
        icon: 'pe-7s-ticket',
        label: 'Entradas',
        category: 'MOVIMIENTOS'
    },
    {
        id: 'MOV001-1',
        parentId: 'MOV001',
        icon: 'pe-7s-ticket',
        label: 'Nueva Entrada',
        to: '/contable/compra/create',
        category: 'MOVIMIENTOS ➜ ENTREGAS'
    },
    {
        id: 'MOV001-2',
        parentId: 'MOV001',
        icon: 'pe-7s-ticket',
        label: 'Lista de Entradas',
        to: '/contable/compras',
        category: 'MOVIMIENTOS ➜ ENTREGAS'
    },
    {
        id: 'MOV002',
        label: 'Salidas',
        icon: 'pe-7s-ticket',
        category: 'MOVIMIENTOS'
    },
    {
        id: 'MOV002-1',
        parentId: 'MOV002',
        icon: 'pe-7s-ticket',
        label: 'Nueva Salida',
        to: '/contable/venta/create',
        category: 'MOVIMIENTOS ➜ SALIDAS'
    },
    {
        id: 'MOV002-2',
        parentId: 'MOV002',
        icon: 'pe-7s-ticket',
        label: 'Lista de Salidas',
        to: '/contable/ventas',
        category: 'MOVIMIENTOS ➜ SALIDAS'
    },
]
export const MantenimientoNav = [
    {
        nro: 0,
        id: 'MAN001',
        icon: 'pe-7s-user',
        label: 'Mantenimiento',
        // to: '/fac/clientes',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 1,
        id: 'MAN001-1',
        parentId: 'MAN001',
        icon: 'pe-7s-user',
        label: 'Clientes',
        to: '/fac/clientes',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 2,
        id: 'MAN001-2',
        parentId: 'MAN001',
        icon: 'pe-7s-user',
        label: 'Proveedores',
        to: '/app/proveedores',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 3,
        id: 'MAN001-3',
        parentId: 'MAN001',
        icon: 'pe-7s-ribbon',
        label: 'Pcge',
        to: '/app/pcge',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 4,
        id: 'MAN001-9',
        parentId: 'MAN001',
        icon: 'pe-7s-cash',
        label: 'Tributos',
        to: '/app/tributos',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 5,
        id: 'MAN001-4',
        parentId: 'MAN001',
        icon: 'pe-7s-edit',
        label: 'Centro de Costos',
        to: '/app/centro-costos',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 6,
        id: 'MAN001-7',
        parentId: 'MAN001',
        icon: 'pe-7s-edit',
        label: 'Det. y Cálculo de Costos',
        to: '/contable/determinacion-calculo-costos',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 7,
        id: 'MAN001-5',
        parentId: 'MAN001',
        icon: 'pe-7s-network',
        label: 'Cuentas Financieras',
        to: '/contable/cuentas_financieras',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 8,
        id: 'MAN001-6',
        parentId: 'MAN001',
        icon: 'pe-7s-cash',
        label: 'Asientos Prediseñados',
        to: '/contable/asientos_predisenados',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 9,
        id: 'MAN001-8',
        parentId: 'MAN001',
        icon: 'pe-7s-cash',
        label: 'Tipos de Adjuntos',
        to: '/app/tipos-adjuntos',
        category: 'MANTENIMIENTO FACTURACIÓN'
    }
]
export const PlanillaNav = [
    {
        nro: 2,
        id: 'PLA001',
        icon: 'pe-7s-clock',
        label: 'Horarios',
        to: '/pla/horario',
        category: 'PLANILLAS'
    },
    {
        nro: 4,
        id: 'PLA002',
        icon: 'pe-7s-albums',
        label: 'Planillas',
        to: '/pla/planilla',
        category: 'PLANILLAS'
    },
    {
        nro: 5,
        id: 'PLA003',
        icon: 'pe-7s-user',
        label: 'Trabajadores',
        to: '/pla/trabajador',
        category: 'PLANILLAS'
    },
    {
        nro: 1,
        id: 'PLA004',
        icon: 'pe-7s-graph2',
        label: 'Comisiones AFP',
        to: '/pla/commission',
        category: 'PLANILLAS'
    },
    {
        nro: 3,
        id: 'PLA005',
        icon: 'pe-7s-graph2',
        label: 'Salarios y Beneficios - C. Civil',
        to: '/pla/salario-beneficios',
        category: 'PLANILLAS'
    }
]
export const PosNav = [
    {
        nro: 1,
        id: 'POS001',
        icon: 'pe-7s-compass',
        label: 'Zonas',
        to: '/pos/zonas',
        category: 'POS'
    },
    {
        nro: 2,
        id: "POS002",
        icon: 'pe-7s-wine',
        label: 'Mesas',
        to: "/pos/mesas",
        category: 'POS'
    },
    {
        nro: 3,
        id: 'POS003',
        icon: 'pe-7s-box1',
        label: 'Cajas',
        to: '/pos/cajas',
        category: 'POS'
    },
    {
        nro: 4,
        id: 'POS004',
        icon: 'pe-7s-add-user',
        label: 'Staff',
        to: '/pos/staff',
        category: 'POS'
    },
    {
        nro: 5,
        id: 'POS005',
        icon: 'pe-7s-note',
        label: 'Toma de Pedidos',
        to: '/mcc/pedido/create',
        category: 'POS'
    },
    {
        nro: 6,
        id: 'POS006',
        icon: 'pe-7s-stopwatch',
        label: 'Cocina',
        to: '/mcc/pedido/create',
        category: 'POS'
    },
    {
        nro: 7,
        id: 'POS007',
        icon: 'pe-7s-piggy',
        label: 'Caja',
        to: '/mcc/pedido/create',
        category: 'POS'
    }
]
export const ReporteNav = [
    {
        nro: 0,
        id: 'REG001',
        icon: 'pe-7s-cash',
        label: 'Libros Físicos',
        // to: '/repo/caja11',
        category: 'REPORTES FACTURACIÓN'
    },
    {
        nro: 1,
        id: 'REG001-1',
        parentId: 'REG001',
        icon: 'pe-7s-cash',
        label: 'Libro Caja',
        to: '/repo/lf/caja',
        category: 'REPORTES FACTURACIÓN'
    },
    {
        nro: 2,
        id: 'REG001-2',
        parentId: 'REG001',
        icon: 'pe-7s-cash',
        label: 'Libro Bancos',
        to: '/repo/lf/bancos',
        category: 'REPORTES FACTURACIÓN'
    },
    {
        nro: 3,
        id: 'REG001-3',
        parentId: 'REG001',
        icon: 'pe-7s-shopbag',
        label: 'Registro de compras',
        to: '/repo/compras',
        category: 'REPORTES FACTURACIÓN'
    },
    {
        nro: 4,
        id: 'REG001-4',
        parentId: 'REG001',
        icon: 'pe-7s-cash',
        label: 'Registro de ventas',
        to: '/repo/ventas',
        category: 'REPORTES FACTURACIÓN'
    },
    {
        nro: 5,
        id: 'REG001-5',
        parentId: 'REG001',
        icon: 'pe-7s-cash',
        label: 'Kardex Valorizado',
        to: '/repo/kardex13',
        category: 'REPORTES FACTURACIÓN'
    },
    {
        nro: 6,
        id: 'REG001-6',
        parentId: 'REG001',
        icon: 'pe-7s-cash',
        label: 'Kardex de Unidades',
        to: '/repo/kardex12',
        category: 'REPORTES FACTURACIÓN'
    },
    {
        nro: 7,
        id: 'REG001-7',
        parentId: 'REG001',
        icon: 'pe-7s-cash',
        label: 'Libro Diario',
        to: '/repo/ldiario',
        category: 'REPORTES FACTURACIÓN'
    },
    {
        nro: 8,
        id: 'REG001-8',
        parentId: 'REG001',
        icon: 'pe-7s-cash',
        label: 'Diario Simplificado',
        to: '/repo/diario',
        category: 'REPORTES FACTURACIÓN'
    },
    {
        nro: 9,
        id: 'REG001-9',
        parentId: 'REG001',
        icon: 'pe-7s-cash',
        label: 'Libro Mayor',
        to: '/repo/mayor',
        category: 'REPORTES FACTURACIÓN'
    },
    {
        nro: 10,
        id: 'REG010',
        icon: 'pe-7s-piggy',
        label: 'Hoja de Trabajo',
        to: '/repo/hoja-trabajo',
        category: 'REPORTES FACTURACIÓN'
    },
]
export const ReporteDivNav = [
    {
        nro: 1,
        id: 'DIV001',
        icon: 'pe-7s-shopbag',
        label: 'Reportes Diversos',
        category: 'REPORTES DIVERSOS'
    },
    {
        nro: 1,
        id: 'DIV001-1',
        parentId: 'DIV001',
        icon: 'pe-7s-shopbag',
        label: 'Reporte de Ventas Detallado',
        to: '/repo/detallado',
        category: 'REPORTES DIVERSOS'
    },
    {
        nro: 2,
        id: 'DIV001-2',
        parentId: 'DIV001',
        icon: 'pe-7s-shopbag',
        label: 'Reporte de Stock',
        to: '/repo/stock',
        category: 'REPORTES DIVERSOS'
    },
    {
        nro: 2.5,
        id: 'DIV001-2.5',
        parentId: 'DIV001',
        icon: 'pe-7s-shopbag',
        label: 'Reporte de Stock por Local',
        to: '/repo/stock-local',
        category: 'REPORTES DIVERSOS'
    },
    {
        nro: 3,
        id: 'DIV001-3',
        parentId: 'DIV001',
        icon: 'pe-7s-shopbag',
        label: 'Costo de Ventas',
        to: '/repo/costos',
        category: 'REPORTES DIVERSOS'
    },
    {
        nro: 4,
        id: 'DIV001-4',
        parentId: 'DIV001',
        icon: 'pe-7s-shopbag',
        label: 'Margen de Utilidad',
        to: '/repo/utilidad',
        category: 'REPORTES DIVERSOS'
    },
    {
        nro: 5,
        id: 'DIV001-5',
        parentId: 'DIV001',
        icon: 'pe-7s-shopbag',
        label: 'Reporte de Cajas',
        to: '/repo/cajas',
        category: 'REPORTES DIVERSOS'
    },
    {
        nro: 6,
        id: 'DIV001-6',
        parentId: 'DIV001',
        icon: 'pe-7s-shopbag',
        label: 'Reporte por Vendedor',
        to: '/repo/vendedor',
        category: 'REPORTES DIVERSOS'
    },
    {
        nro: 7,
        id: 'DIV001-7',
        parentId: 'DIV001',
        icon: 'pe-7s-shopbag',
        label: 'Ranking de Productos',
        to: '/repo/rankingproductos',
        category: 'REPORTES DIVERSOS'
    },
    {
        nro: 8,
        id: 'DIV001-8',
        parentId: 'DIV001',
        icon: 'pe-7s-shopbag',
        label: 'Ranking de Clientes',
        to: '/repo/rankingclientes',
        category: 'REPORTES DIVERSOS'
    },
    {
        nro: 9,
        id: 'DIV001-9',
        parentId: 'DIV001',
        icon: 'pe-7s-shopbag',
        label: 'Ranking de Proveedores',
        to: '/repo/rankingproveedores',
        category: 'REPORTES DIVERSOS'
    },
    {
        nro: 10,
        id: 'DIV001-10',
        parentId: 'DIV001',
        icon: 'pe-7s-shopbag',
        label: 'Reporte Movimientos',
        to: '/repo/movimientos',
        category: 'REPORTES DIVERSOS'
    },
    {
        nro: 11,
        id: 'DIV001-11',
        parentId: 'DIV001',
        icon: 'pe-7s-shopbag',
        label: 'Reporte Consolidado por Producto',
        to: '/repo/movimientosconsolidado',
        category: 'REPORTES DIVERSOS'
    },
    {
        nro: 12,
        id: 'DIV001-12',
        parentId: 'DIV001',
        icon: 'pe-7s-shopbag',
        label: 'Reporte Osinergmin',
        to: '/repo/osinergmin',
        category: 'REPORTES DIVERSOS'
    },
    {
        nro: 13,
        id: 'DIV001-13',
        parentId: 'DIV001',
        icon: 'pe-7s-shopbag',
        label: 'Ventas Acciona',
        to: '/repo/acciona/ventas',
        category: 'REPORTES DIVERSOS'
    },
    {
        nro: 14,
        id: 'DIV001-14',
        parentId: 'DIV001',
        icon: "pe-7s-cash",
        label: "Stock interno",
        to: "/repo/stock-interno",
        category: 'REPORTES DIVERSOS'
    },
    
]
export const ConsultaSunatNav = [
    {
        nro: 1,
        id: 'SUNAT001',
        icon: 'pe-7s-note2',
        label: 'Recibos por Honorarios',
        to: '/consultas-sunat/rrhh',
        category: 'CONSULTAS SUNAT'
    },
    {
        nro: 2,
        id: 'SUNAT002',
        icon: 'pe-7s-cash',
        label: 'Depósito de Detracciones',
        to: '/consultas-sunat/deposito-detracciones',
        category: 'CONSULTAS SUNAT'
    },
    {
        nro: 3,
        id: 'SUNAT003',
        icon: 'pe-7s-wallet',
        label: 'Consulta NPD',
        to: '/consultas-sunat/consulta-npd',
        category: 'CONSULTAS SUNAT'
    },
    {
        nro: 4,
        id: 'SUNAT004',
        icon: 'pe-7s-cash',
        label: 'Declaraciones y Pagos',
        to: '/consultas-sunat/declaraciones-pagos',
        category: 'CONSULTAS SUNAT'
    },
    {
        nro: 5,
        id: 'SUNAT005',
        icon: 'pe-7s-note2',
        label: 'Consulta Constancias',
        to: '/consultas-sunat/consulta-constancias',
        category: 'CONSULTAS SUNAT'
    },
]
export const UtilitariosNav = [
    {
        nro: 1,
        id: 'UTL001',
        icon: 'pe-7s-shopbag',
        label: 'Importación masiva',
        category: 'UTILITARIOS FACTURACIÓN'
    },
    {
        nro: 2,
        id: "UTL001-1",
        parentId: 'UTL001',
        icon: 'pe-7s-shopbag',
        label: 'Categorías',
        to: '/util/categorias',
        category: 'UTILITARIOS FACTURACIÓN ➜ IMPORTACIÓN MASIVA'
    },
    {
        nro: 3,
        id: 'UTL001-2',
        parentId: 'UTL001',
        icon: 'pe-7s-shopbag',
        label: 'Productos',
        to: '/util/productos',
        category: 'UTILITARIOS FACTURACIÓN ➜ IMPORTACIÓN MASIVA'
    },
    {
        nro: 4,
        id: 'UTL001-3',
        parentId: 'UTL001',
        icon: 'pe-7s-shopbag',
        label: 'Saldos',
        to: '/util/saldos',
        category: 'UTILITARIOS FACTURACIÓN ➜ IMPORTACIÓN MASIVA'
    },
    {
        nro: 5,
        id: 'UTL001-4',
        parentId: 'UTL001',
        icon: 'pe-7s-shopbag',
        label: 'Clientes',
        to: '/util/clientes',
        category: 'UTILITARIOS FACTURACIÓN ➜ IMPORTACIÓN MASIVA'
    },
    {
        nro: 6,
        id: "UTL001-5",
        parentId: 'UTL001',
        icon: 'pe-7s-shopbag',
        label: 'Proveedores',
        to: '/util/proveedores',
        category: 'UTILITARIOS FACTURACIÓN ➜ IMPORTACIÓN MASIVA'
    },
    {
        nro: 7,
        id: 'UTL002',
        icon: 'pe-7s-shopbag',
        label: 'Generador Asientos Cont.',
        category: 'UTILITARIOS CONTABLES'
    },
    {
        nro: 8,
        id: "UTL002-1",
        parentId: 'UTL002',
        icon: 'pe-7s-shopbag',
        label: 'PLE',
        category: 'UTILITARIOS CONTABLES ➜ GENERADOR DE ASIENTOS CONTABLES'
    },
    {
        nro: 9,
        id: "UTL002-1-1",
        parentId: 'UTL002-1',
        icon: 'pe-7s-shopbag',
        label: 'Compras 8.1',
        to: '/util/import81',
        category: 'UTILITARIOS CONTABLES ➜ GENERADOR DE ASIENTOS CONTABLES  ➜ PLE'
    },
    {
        nro: 10,
        id: "UTL002-1-2",
        parentId: 'UTL002-1',
        icon: 'pe-7s-shopbag',
        label: 'Ventas 14.1',
        to: '/util/import141',
        category: 'UTILITARIOS CONTABLES ➜ GENERADOR DE ASIENTOS CONTABLES  ➜ PLE'
    },
    {
        nro: 11,
        id: "UTL002-3",
        parentId: 'UTL002-1',
        icon: 'pe-7s-shopbag',
        label: 'Diario 5.1',
        to: '/util/import51',
        category: 'UTILITARIOS CONTABLES ➜ GENERADOR DE ASIENTOS CONTABLES  ➜ PLE',
    },
    {
        nro: 12,
        id: "UTL002-2",
        parentId: 'UTL002',
        icon: 'pe-7s-shopbag',
        label: 'SIRE',
        category: 'UTILITARIOS CONTABLES ➜ GENERADOR DE ASIENTOS CONTABLES'
    },
    {
        nro: 13,
        id: 'UTL002-2-1',
        parentId: 'UTL002-2',
        icon: 'pe-7s-shopbag',
        label: 'SIRE Ventas 14.1',
        category: 'UTILITARIOS CONTABLES ➜ GENERADOR DE ASIENTOS CONTABLES ➜ SIRE',
        to: '/util/sire141'
    },
    {
        nro: 14,
        id: 'UTL002-2-2',
        parentId: 'UTL002-2',
        icon: 'pe-7s-shopbag',
        label: 'SIRE Compras 8.1',
        category: 'UTILITARIOS CONTABLES ➜ GENERADOR DE ASIENTOS CONTABLES  ➜  SIRE',
        to: '/util/sire081'
    },
    {
        nro: 0,
        id: "UTL007",
        icon: 'pe-7s-paper-plane',
        label: 'Facturación electrónica masiva ',
        to: '/util/facturacion',
        category: 'UTILITARIOS FACTURACIÓN ➜ IMPORTACIÓN MASIVA'
    },
    {
        nro: 15,
        id: 'UTL004',
        icon: 'pe-7s-shopbag',
        label: 'Liquidación de Impuestos',
        category: 'UTILITARIOS CONTABLES',
        to: '/util/liquidacion-impuestos'
    },
    {
        nro: 17,
        id: 'UTL014',
        icon: 'pe-7s-shopbag',
        label: 'SUNAT',
        category: 'UTILITARIOS CONTABLES'
    },
    {
        nro: 18,
        id: 'UTL014-1',
        parentId: 'UTL014',
        icon: 'pe-7s-shopbag',
        label: 'DAOT PDT - 3500',
        category: 'UTILITARIOS CONTABLES ➜ SUNAT'
    },
    {
        nro: 19,
        id: "UTL014-1-1",
        parentId: 'UTL014-1',
        icon: 'pe-7s-cash',
        label: 'Costos y/o Gastos',
        to: '/contable/daot/compras',
        category: 'UTILITARIOS CONTABLES ➜ SUNAT ➜ UTILITARIOS DAOT'
    },
    {
        nro: 20,
        id: 'UTL014-1-2',
        parentId: 'UTL014-1',
        icon: 'pe-7s-cash',
        label: 'Ingresos',
        to: '/contable/daot/ventas',
        category: 'UTILITARIOS CONTABLES ➜ SUNAT ➜ UTILITARIOS DAOT'
    },
    {
        nro: 21,
        id: 'UTL014-2',
        parentId: 'UTL014',
        icon: 'pe-7s-shopbag',
        label: 'Detracciones NPD',
        category: 'UTILITARIOS CONTABLES ➜ SUNAT'
    },
    {
        nro: 22,
        id: 'UTL014-2-1',
        parentId: 'UTL014-2',
        icon: 'pe-7s-cloud-upload',
        label: 'Autodetracción',
        to: '/contable/npd/ventas',
        category: 'UTILITARIOS CONTABLES ➜ SUNAT ➜ UTILITARIOS NPD'
    },
    {
        nro: 23,
        id: 'UTL014-2-2',
        parentId: 'UTL014-2',
        icon: 'pe-7s-cloud-upload',
        label: 'Detracciones',
        to: '/contable/npd/compras',
        category: 'UTILITARIOS CONTABLES ➜ SUNAT ➜ UTILITARIOS NPD'
    },
    {
        nro: 24,
        id: 'UTL014-3',
        parentId: 'UTL014',
        icon: 'pe-7s-shopbag',
        label: 'Validez De CPE',
        category: 'UTILITARIOS CONTABLES ➜ SUNAT'
    },
    {
        nro: 25,
        id: 'UTL014-3-1',
        parentId: 'UTL014-3',
        icon: 'pe-7s-cloud-upload',
        label: 'Reg. Ventas TXT - PLE',
        to: '/util/cpe/ventas',
        category: 'UTILITARIOS CONTABLES ➜ SUNAT ➜ UTILITARIOS CPE'
    },
    {
        nro: 26,
        id: 'UTL014-3-2',
        parentId: 'UTL014-3',
        icon: 'pe-7s-cloud-upload',
        label: 'Reg. Compras TXT - PLE',
        to: '/util/cpe/compras',
        category: 'UTILITARIOS CONTABLES ➜ SUNAT ➜ UTILITARIOS CPE'
    },
    {
        nro: 27,
        id: 'UTL001-9',
        parentId: 'UTL001',
        icon: 'pe-7s-shopbag',
        label: 'Modelos',
        to: '/util/modelos',
        category: 'UTILITARIOS FACTURACIÓN ➜ IMPORTACIÓN MASIVA'
    },
    {
        nro: 28,
        id: 'UTL001-10',
        parentId: 'UTL001',
        icon: 'pe-7s-shopbag',
        label: 'Precios',
        to: '/util/precios',
        category: 'UTILITARIOS FACTURACIÓN ➜ IMPORTACIÓN MASIVA'
    },
    {
        nro: 29,
        id: 'UTL0013',
        icon: 'pe-7s-shopbag',
        label: 'Varios',
        category: 'UTILITARIOS CONTABLES',
    },
    {
        nro: 30,
        id: 'UTL0013-1',
        parentId: 'UTL0013',
        icon: 'pe-7s-shopbag',
        label: 'Analizador de Asientos',
        category: 'UTILITARIOS CONTABLES ➜ VARIOS',
        to: '/util/analizador_asientos'
    },
    {
        nro: 31,
        id: 'UTL0013-2',
        parentId: 'UTL0013',
        icon: 'pe-7s-shopbag',
        label: 'Corrector de Asientos de Destino',
        category: 'UTILITARIOS CONTABLES ➜ VARIOS',
        to: '/util/corrector_asientos_destino'
    },
    {
        nro: 32,
        id: 'UTL0013-3',
        parentId: 'UTL0013',
        icon: 'pe-7s-shopbag',
        label: `Reprocesar CUO's`,
        category: 'UTILITARIOS CONTABLES ➜ VARIOS',
        to: '/util/reprocesar-cuos'
    },
    {
        nro: 16,
        id: 'UTL018',
        icon: 'pe-7s-shopbag',
        label: 'Conciliación Bancaria',
        category: 'UTILITARIOS CONTABLES',
        to: '/util/conciliacion_bancaria'
    },
    {
        nro: 28,
        id: 'UTL019',
        icon: 'pe-7s-notebook',
        label: 'Biblioteca Digital',
        category: 'UTILITARIOS CONTABLES',
        to: '/util/biblioteca-digital'
    },
]
export const SolNav = [
    {
        nro: 1,
        id: 'SOL001',
        icon: 'pe-7s-cloud-upload',
        label: 'DETRACCIONES (NPD)',
        category: 'PROCESOS SOL'
    },
    {
        nro: 2,
        id: "SOL001-1",
        parentId: 'SOL001',
        label: 'Proveedor - Adquirientes',
        to: '/operaciones/detraccion/provclie',
        category: 'PROCESOS SOL ➜ DETRACCIONES (NPD)'
    },
    {
        nro: 3,
        id: 'SOL001-2',
        parentId: 'SOL001',
        label: 'Adquiriente - Proveedores',
        to: '/operaciones/detraccion/clieprov',
        category: 'PROCESOS SOL ➜ DETRACCIONES (NPD)'
    },
    {
        nro: 5,
        id: 'SOL002',
        icon: 'pe-7s-cloud-upload',
        label: 'ING RECAUDACIÓN F.V 4702',
        category: 'PROCESOS SOL',
        to: '/operaciones/detraccion/descargo',
    },
]
export const PdtNav = [
    {
        nro: 1,
        id: 'PDT001',
        icon: 'pe-7s-note2',
        label: 'FORM. VIRTUAL 621',
        category: 'PDTS'
    },
    {
        nro: 2,
        id: "PDT001-1",
        parentId: 'PDT001',
        label: 'RETENCIONES ',
        to: '/pdts/retenciones/comprobante-retencion',
        category: 'PDTS ➜ FORM. VIRTUAL 621'
    },
    {
        nro: 3,
        id: "PDT001-2",
        parentId: 'PDT001',
        label: 'PERCEPCIONES',
        category: 'PDTS ➜ FORM. VIRTUAL 621'
    },
    {
        nro: 4,
        id: 'PDT001-2-1',
        parentId: 'PDT001-2',
        label: 'Percepción en Factura',
        to: '/pdts/percepciones/percepcion-en-factura',
        category: 'PDTS ➜ FORM. VIRTUAL 621 ➜ PERCEPCIONES'
    },
    {
        nro: 5,
        id: 'PDT001-2-2',
        parentId: 'PDT001-2',
        label: 'Compr. de Percepción',
        to: '/pdts/percepciones/comprobante-percepcion',
        category: 'PDTS ➜ FORM. VIRTUAL 621 ➜ PERCEPCIONES'
    },
    {
        nro: 6,
        id: 'PDT002',
        icon: 'pe-7s-cloud-upload',
        label: 'PDT 3500 - DAOT',
        category: 'PDTS'
    },
    {
        nro: 7,
        id: "PDT002-1",
        parentId: 'PDT002',
        label: 'Oper. generan Costo/Gasto',
        to: '/pdts/daot/costos',
        category: 'PDTS ➜ PDT 3500 - DAOT'
    },
    {
        nro: 8,
        id: "PDT002-2",
        parentId: 'PDT002',
        label: 'Oper. generan Ingreso',
        to: '/pdts/daot/ingresos',
        category: 'PDTS ➜ PDT 3500 - DAOT'
    },
]
export const XmlNav = [
    {
        nro: 1,
        id: "XML001",
        icon: 'pe-7s-cart',
        label: 'Reg. Compras',
        to: '/xml/registro/compras',
        category: 'IMPORTACIÓN MASIVA XML'
    },
    {
        nro: 2,
        id: "XML002",
        icon: 'pe-7s-cart',
        label: 'Reg. Ventas',
        to: '/xml/registro/ventas',
        category: 'IMPORTACIÓN MASIVA XML'
    }
]
export const LibyRegNav = [
    {
        nro: 1,
        id: "LIB001",
        icon: 'pe-7s-note2',
        label: 'Libro Diario',
        to: '/lrcontables/diario-mensual',
        category: 'LIBROS Y REGISTROS CONTABLES'
    },
    {
        nro: 2,
        id: "LIB002",
        icon: 'pe-7s-ribbon',
        label: 'Libro Mayor',
        to: '/lrcontables/libro-mayor',
        category: 'LIBROS Y REGISTROS CONTABLES'
    },
    {
        nro: 3,
        id: "LIB003",
        icon: 'pe-7s-note2',
        label: 'Diario Simplificado',
        to: '/lrcontables/diario-simplificado',
        category: 'LIBROS Y REGISTROS CONTABLES'
    },
    {
        nro: 4,
        id: "LIB004",
        icon: 'pe-7s-map',
        label: 'Hoja de Trabajo',
        to: '/lrcontables/hoja-trabajo',
        category: 'LIBROS Y REGISTROS CONTABLES'
    },
    {
        nro: 5,
        id: "LIB005",
        icon: 'pe-7s-display2',
        label: 'Estados Financieros',
        category: 'LIBROS Y REGISTROS CONTABLES'
    },
    {
        nro: 6,
        id: 'LIB005-1',
        parentId: 'LIB005',
        label: 'Balance General',
        to: '/lrcontables/bgc',
        category: 'LIBROS Y REGISTROS CONTABLES ➜ ESTADOS FINANCIEROS'
    },
    {
        nro: 7,
        id: 'LIB005-2',
        parentId: 'LIB005',
        label: 'E.G. y P-Naturaleza',
        to: '/lrcontables/ern',
        category: 'LIBROS Y REGISTROS CONTABLES ➜ ESTADOS FINANCIEROS'
    },
    {
        nro: 8,
        id: 'LIB005-3',
        parentId: 'LIB005',
        label: 'E.G. y P-Función',
        to: '/lrcontables/erf',
        category: 'LIBROS Y REGISTROS CONTABLES ➜ ESTADOS FINANCIEROS'
    },
    {
        nro: 9,
        id: "LIB006",
        label: 'Plan de Cuentas',
        icon: 'pe-7s-display1',
        category: 'LIBROS Y REGISTROS CONTABLES'
    },
    {
        nro: 10,
        id: 'LIB006-1',
        parentId: 'LIB006',
        label: 'PCGE 2010',
        to: '/lrcontables/pcge/2010',
        category: 'LIBROS Y REGISTROS CONTABLES ➜ PLAN DE CUENTAS'
    },
    {
        nro: 11,
        id: 'LIB006-2',
        parentId: 'LIB006',
        label: 'PCGE 2020',
        to: '/lrcontables/pcge/2020',
        category: 'LIBROS Y REGISTROS CONTABLES ➜ PLAN DE CUENTAS'
    },
]
export const LibroEcocont = [
    {
        nro: 0,
        id: 'ECO001',
        icon: 'pe-7s-cash',
        // to: '/repo/caja11',
        label: 'Libros Electrónicos',
        category: 'LIBROS ELECTRÒNICOS'
    },
    {
        nro: 0,
        id: 'ECO001-1',
        parentId: 'ECO001',
        icon: 'pe-7s-cash',
        label: 'Libro Caja',
        to: '/repo/caja11',
        category: 'LIBROS ELECTRÒNICOS'
    },
    {
        nro: 1,
        id: 'ECO001-2',
        parentId: 'ECO001',
        icon: 'pe-7s-cash',
        label: 'Libro Bancos',
        to: '/repo/bancos12',
        category: 'LIBROS ELECTRÓNICOS'
    },
    {
        nro: 2,
        id: 'ECO001-3',
        parentId: 'ECO001',
        icon: 'pe-7s-shopbag',
        label: 'Registro de compras',
        category: 'LIBROS ELECTRÓNICOS'
    },
    {
        nro: 3,
        id: 'ECO001-3-1',
        parentId: 'ECO001-3',
        label: 'Compras 8.1',
        to: '/repo/compras81',
        category: 'LIBROS ELECTRÒNICOS ➜ REGISTRO COMPRAS'
    },
    {
        nro: 4,
        id: 'ECO001-3-2',
        parentId: 'ECO001-3',
        label: 'Compras 8.2',
        to: '/repo/compras82',
        category: 'LIBROS ELECTRÒNICOS ➜ REGISTRO COMPRAS'
    },
    {
        nro: 5,
        id: 'ECO001-3-3',
        parentId: 'ECO001-3',
        label: 'Compras 8.3',
        to: '/repo/compras83',
        category: 'LIBROS ELECTRÒNICOS ➜ REGISTRO COMPRAS'
    },
    {
        nro: 6,
        id: 'ECO001-4',
        parentId: 'ECO001',
        icon: 'pe-7s-shopbag',
        label: 'Registro de Ventas',
        category: 'LIBROS ELECTRÒNICOS'
    },
    {
        nro: 7,
        id: 'ECO001-4-1',
        parentId: 'ECO001-4',
        label: 'Ventas 14.1',
        to: '/repo/ventas141',
        category: 'LIBROS ELECTRÒNICOS ➜ REGISTRO VENTAS'
    },
    {
        nro: 8,
        id: 'ECO001-4-2',
        parentId: 'ECO001-4',
        label: 'Ventas 14.2',
        to: '/repo/ventas142',
        category: 'LIBROS ELECTRÒNICOS ➜ REGISTRO VENTAS'
    },
    {
        nro: 9,
        id: 'ECO001-5',
        parentId: 'ECO001',
        icon: 'pe-7s-cash',
        label: 'Kardex Valorizado',
        to: '/repo/kardex13',
        category: 'LIBROS ELECTRÒNICOS'
    },
    {
        nro: 10,
        id: 'ECO001-6',
        parentId: 'ECO001',
        icon: 'pe-7s-cash',
        label: 'Kardex de Unidades',
        to: '/repo/kardex12',
        category: 'LIBROS ELECTRÒNICOS'
    },
    {
        nro: 11,
        id: 'ECO001-7',
        parentId: 'ECO001',
        icon: 'pe-7s-cash',
        label: 'Libro Diario',
        category: 'LIBROS ELECTRÒNICOS'
    },
    {
        nro: 12,
        id: 'ECO001-7-1',
        parentId: 'ECO001-7',
        icon: 'pe-7s-shopbag',
        label: 'Libro Diario',
        to: '/repo/diario51',
        category: 'LIBROS ELECTRÒNICOS ➜ LIBRO DIARIO'
    },
    {
        nro: 13,
        id: 'ECO001-7-2',
        parentId: 'ECO001-7',
        icon: 'pe-7s-shopbag',
        label: 'PCGE',
        to: '/repo/diario53',
        category: 'LIBROS ELECTRÒNICOS ➜ LIBRO DIARIO'
    },
    {
        nro: 14,
        id: 'ECO001-8',
        parentId: 'ECO001',
        icon: 'pe-7s-cash',
        label: 'Diario Simplificado',
        category: 'LIBROS ELECTRÒNICOS'
    },
    {
        nro: 15,
        id: 'ECO001-8-1',
        parentId: 'ECO001-8',
        icon: 'pe-7s-shopbag',
        label: 'Libro Diario',
        to: '/repo/diario52',
        category: 'LIBROS ELECTRÒNICOS ➜ DIARIO SIMPLIFICADO'
    },
    {
        nro: 16,
        id: 'ECO001-8-2',
        parentId: 'ECO001-8',
        icon: 'pe-7s-shopbag',
        label: 'PCGE',
        to: '/repo/diario54',
        category: 'LIBROS ELECTRÓNICOS ➜ DIARIO SIMPLIFICADO'
    },
    {
        nro: 17,
        id: 'ECO001-9',
        parentId: 'ECO001',
        icon: 'pe-7s-cash',
        label: 'Libro Mayor',
        to: '/repo/mayor61',
        category: 'LIBROS ELECTRÒNICOS'
    },
]
export const OperPla = [
    {
        nro: 1,
        id: 'PPL001',
        icon: 'pe-7s-check',
        label: 'Registro de Asistencia',
        to: '/pla/operation/assistance',
        category: 'OPERACIONES PLANILLAS'
    },
    {
        nro: 4,
        id: 'PLE003',
        icon: 'pe-7s-cash',
        label: 'Part. de Utilidades',
        to: '/pla/utilidades',
        category: 'OPERACIONES PLANILLAS'
    },
    {
        nro: 3,
        id: 'PPL003',
        icon: 'pe-7s-cash',
        label: 'Planilla Mensual',
        to: '/pla/plan-mensual',
        category: 'OPERACIONES PLANILLAS'
    },
    {
        nro: 5,
        id: 'PLE002',
        icon: 'pe-7s-cash',
        label: 'PDT - PLAME',
        to: '/pla/plame',
        category: 'OPERACIONES PLANILLAS'
    },
    {
        nro: 6,
        id: 'PLM001',
        icon: 'pe-7s-cash',
        label: 'AFP NET',
        to: '/repo/lf/afp',
        category: 'OPERACIONES PLANILLAS'
    },
    {
        nro: 2,
        id: 'PLR001',
        icon: 'pe-7s-cash',
        label: "Recibo por honorarios",
        to: '/pla/compras/recibos',
        category: 'OPERACIONES PLANILLAS'
    }
]
export const ReportesPla = [
    {
        nro: 1,
        id: 'AFP001',
        icon: 'pe-7s-note2',
        label: 'Reporte de Asistencia',
        to: '/pla/reporte-asistencia',
        category: 'REPORTES PLANILLAS'
    },
    {
        nro: 2,
        id: 'AFP002',
        icon: 'pe-7s-ribbon',
        label: 'Reporte de Renta de Quinta',
        to: '/pla/reporte-rentaquinta',
        category: 'REPORTES PLANILLAS'
    },
    {
        nro: 3,
        id: 'AFP003',
        icon: 'pe-7s-display2',
        label: 'Reporte de CTS',
        to: '/pla/reporte-cts',
        category: 'REPORTES PLANILLAS'
    },
    {
        nro: 4,
        id: 'AFP004',
        icon: 'pe-7s-albums',
        label: 'Reporte de Gratificaciones',
        to: '/pla/reporte-gratificaciones',
        category: 'REPORTES PLANILLAS'
    },
    {
        nro: 5,
        id: 'AFP006',
        icon: 'pe-7s-albums',
        label: 'Reporte de Vacaciones',
        to: '/pla/reporte-vacaciones',
        category: 'REPORTES PLANILLAS'
    },
    {
        nro: 6,
        id: 'AFP005',
        icon: 'pe-7s-news-paper',
        label: 'Reporte de Planillas',
        to: '/pla/reporte-planillas',
        category: 'REPORTES PLANILLAS'
    },
]

export const ManFactura = [
    {
        nro: 1,
        id: 'MAF001',
        icon: 'pe-7s-cash',
        label: 'Mantenimiento',
        // to: '/app/clientes',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 1,
        id: 'MAF001-1',
        parentId: 'MAF001',
        icon: 'pe-7s-cash',
        label: 'Clientes',
        to: '/app/clientes',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 2,
        id: 'MAF001-2',
        parentId: 'MAF001',
        icon: 'pe-7s-cash',
        label: 'Proveedores',
        to: '/app/proveedores',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 3,
        id: 'MAF001-3',
        parentId: 'MAF001',
        icon: 'pe-7s-ticket',
        label: 'Categorias',
        to: '/fac/categorias',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 4,
        id: 'MAF001-4',
        parentId: 'MAF001',
        icon: 'pe-7s-box1',
        label: 'Productos',
        to: '/fac/productos',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 9,
        id: 'MAF001-9',
        parentId: 'MAF001',
        icon: 'pe-7s-box1',
        label: 'Precios',
        to: '/fac/precios',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 5,
        id: 'MAF001-5',
        parentId: 'MAF001',
        icon: 'pe-7s-car',
        label: 'Transportistas',
        to: '/fac/transportistas',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 6,
        id: 'MAF001-6',
        parentId: 'MAF001',
        icon: 'pe-7s-cash',
        label: 'Cuentas Financieras',
        to: '/contable/cuentas_financieras',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 7,
        id: 'MAF001-7',
        parentId: 'MAF001',
        icon: 'pe-7s-cash',
        label: 'Responsables',
        to: '/app/responsables',
        category: 'MANTENIMIENTO FACTURACIÓN'
    },
    {
        nro: 8,
        id: 'MAF001-8',
        parentId: 'MAF001',
        icon: 'pe-7s-box1',
        label: 'Combos',
        to: '/fac/combos',
        category: 'MANTENIMIENTO FACTURACIÓN'
    }
]
export const UtilitariosPla = [
    {
        nro: 1,
        id: 'PPL002',
        icon: 'pe-7s-upload',
        label: 'Imp. de Asistencia',
        to: '/pla/importar-asistencia',
        category: 'UTILITARIOS PLANILLAS'
    },
    {
        nro: 2,
        id: 'PPL004',
        icon: 'pe-7s-upload',
        label: 'Imp. de Conceptos - PLAME',
        to: '/pla/importar-conceptos',
        category: 'UTILITARIOS PLANILLAS'
    },
    {
        nro: 3,
        id: 'UPL001',
        icon: 'pe-7s-upload',
        label: 'Imp. Horas Extras y/o tardanzas',
        to: '/pla/extras-tardanzas',
        category: 'UTILITARIOS PLANILLAS'
    },
    {
        nro: 4,
        id: 'UPL002',
        icon: 'pe-7s-upload',
        label: 'Imp. de Tardanzas',
        to: '/app/importar-tardanzas',
        category: 'UTILITARIOS PLANILLAS'
    },
    {
        nro: 5,
        id: 'UPL003',
        icon: 'pe-7s-upload',
        label: 'Imp. de Faltas Injust.',
        to: '/pla/importar-faltas',
        category: 'UTILITARIOS PLANILLAS'
    }
]

export const ReportDivNav = [
    {
        nro: 0,
        id: 'REV001',
        icon: 'pe-7s-shopbag',
        label: 'Reportes Diversos',
        // to: '/repo/reportes-diversos',
        category: 'REPORTES DIVERSOS'
    },
    {
        id: 'REV001-1',
        parentId: 'REV001',
        label: 'Compras y Ventas',
        to: '/repo/diversos/compras-ventas',
        category: 'REPORTES DIVERSOS'
    },
    {
        id: 'REV001-2',
        parentId: 'REV001',
        label: 'Tributarios',
        to: '/repo/diversos/tributos',
        category: 'REPORTES DIVERSOS'
    },
    {
        id: 'REV001-3',
        parentId: 'REV001',
        label: 'Análisis de Cuentas',
        to: '/repo/diversos/analisis-cuentas',
        category: 'REPORTES DIVERSOS'
    },
    {
        id: 'REV001-4',
        parentId: 'REV001',
        label: 'Caja y bancos',
        to: '/repo/diversos/cajas-bancos',
        category: 'REPORTES DIVERSOS'
    }
]

export const SireNav = [
    {
        nro: 0,
        id: 'RVIE001',
        icon: 'pe-7s-shopbag',
        label: 'RVIE 14.4',
        category: 'SIRE'
    },
    {
        id: 'RVIE001-1',
        parentId: 'RVIE001',
        label: 'Resumen',
        to: '/sire/rvie/dashboard',
        category: 'SIRE'
    },
    {
        id: 'RVIE001-2',
        parentId: 'RVIE001',
        label: 'ECOCONT',
        to: '/sire/rvie/ecocont',
        category: 'SIRE'
    },
    {
        id: 'RVIE001-3',
        parentId: 'RVIE001',
        label: 'SIRE-SUNAT',
        to: '/sire/rvie/sunat',
        category: 'SIRE'
    },
    {
        id: 'RVIE001-4',
        parentId: 'RVIE001',
        label: 'Comparar',
        to: '/sire/rvie/comparar',
        category: 'SIRE'
    },
    {
        id: 'RVIE001-5',
        parentId: 'RVIE001',
        label: 'Ajustes Posteriores',
        category: 'SIRE'
    },
    {
        id: 'RVIE001-5-1',
        parentId: 'RVIE001-5',
        label: 'SIRE',
        to: '/sire/rvie/ajustes/sire',
        category: 'SIRE'
    },
    {
        id: 'RVIE001-5-2',
        parentId: 'RVIE001-5',
        label: 'PLE',
        to: '/sire/rvie/ajustes/ple',
        category: 'SIRE'
    },
    {
        id: 'RVIE001-6',
        parentId: 'RVIE001',
        label: 'Historial',
        to: '/sire/rvie/historial',
        category: 'SIRE'
    },
    {
        nro: 0,
        id: 'RCE001',
        icon: 'pe-7s-cart',
        label: 'RCE',
        category: 'SIRE'
    },
    {
        id: 'RCE001-1',
        parentId: 'RCE001',
        icon: 'pe-7s-shopbag',
        label: 'RCE 8.4',
        category: 'SIRE'
    },
    {
        id: 'RCE001-1-1',
        parentId: 'RCE001-1',
        label: 'Resumen',
        to: '/sire/rce/dashboard',
        category: 'SIRE'
    },
    {
        id: 'RCE001-1-2',
        parentId: 'RCE001-1',
        label: 'ECOCONT',
        to: '/sire/rce/ecocont',
        category: 'SIRE'
    },
    {
        id: 'RCE001-1-3',
        parentId: 'RCE001-1',
        label: 'SIRE-SUNAT',
        to: '/sire/rce/sunat',
        category: 'SIRE'
    },
    {
        id: 'RCE001-1-4',
        parentId: 'RCE001-1',
        label: 'Comparar',
        to: '/sire/rce/comparar',
        category: 'SIRE'
    },
    {
        id: 'RCE001-1-5',
        parentId: 'RCE001-1',
        label: 'Ajustes Posteriores',
        category: 'SIRE'
    },
    {
        id: 'RCE001-1-5-1',
        parentId: 'RCE001-1-5',
        label: 'SIRE',
        to: '/sire/rce/ajustes/sire',
        category: 'SIRE'
    },
    {
        id: 'RCE001-1-5-2',
        parentId: 'RCE001-1-5',
        label: 'PLE',
        to: '/sire/rce/ajustes/ple',
        category: 'SIRE'
    },
    {
        id: 'RCE001-1-6',
        parentId: 'RCE001-1',
        label: 'Historial',
        to: '/sire/rce/historial',
        category: 'SIRE'
    },
    {
        id: 'RCE001-2',
        parentId: 'RCE001',
        icon: 'pe-7s-shopbag',
        label: 'RCE-ND (8.5)',
        to: '/sire/rce/ecocont85',
        category: 'SIRE'
    },
    {
        id: 'RVIE002',
        label: 'Generación de Registros',
        to: '/sire/generar',
        icon: 'pe-7s-check',
        category: 'SIRE'
    },
    {
        id: 'RVIE003',
        label: "Lista de Ticket's",
        to: '/sire/ticket',
        icon: 'pe-7s-note2',
        category: 'SIRE'
    }
]
